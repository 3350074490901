import Layout from '@/layout'
import { getCookie } from '../assets/js/cookie';

let redirectUrl = "";

if (getCookie()) {
  redirectUrl = "/home";
} else {
  redirectUrl = "/login";
}
const routes = [
  {
    path: '/',
    redirect: redirectUrl
  },
  {
      name: 'login',
      path: '/login',
      component: () => import('@/views/login')
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('@/views/login/register')
  },
  {
    path: '/home',
    component: Layout,
    children: [{
      path: '/home',
      name: 'home',
      component: () => import('@/views/home')
    }]
  },
  {
    path: '/member',
    component: Layout,
    children: [{
      path: '/member',
      name: 'member',
      component: () => import('@/views/member')
    }]
  },
  {
    path: '/record',
    component: Layout,
    children: [{
      path: '/record',
      name: 'record',
      component: () => import('@/views/record')
    }]
  },
  {
    path: '/notice',
    component: Layout,
    children: [{
      path: '/notice',
      name: 'notice',
      component: () => import('@/views/notice')
    }]
  },
  {
    path: '/noticeDetail',
    component: Layout,
    children: [{
      path: '/noticeDetail',
      name: 'noticeDetail',
      component: () => import('@/views/noticeDetail')
    }]
  },
  {
    name: 'auth',
    path: '/auth',
    component: () => import('@/views/auth')
  },
  {
    path: '/company',
    mate: {keepAlive: true},
    component: Layout,
    children: [{
      path: '/company',
      name: 'company',
      component: () => import('@/views/company')
    }]
  },
  {
    path: '/analysis',
    component: Layout,
    children: [{
      path: '/analysis',
      name: 'analysis',
      component: () => import('@/views/analysis')
    }]
  },
  {
      path: '/new',
      component: Layout,
      children: [{
        path: '/new',
        name: 'new',
        component: () => import('@/views/new')
      }]
  },
  {
    path: '/apply',
    component: Layout,
    children: [{
      path: '/apply',
      name: 'apply',
      component: () => import('@/views/apply')
    }]
  },
  {
    path: '/editor',
    component: Layout,
    children: [{
      path: '/editor',
      name: 'editor',
      component: () => import('@/views/editor')
    }]
  },
  {
    path: '/balance',
    component: Layout,
    children: [{
      path: '/balance',
      name: 'balance',
      component: () => import('@/views/balance')
    }]
  },
  // {
  //   path: '/submitResult',
  //   component: Layout,
  //   children: [{
  //     path: '/submitResult',
  //     name: 'submitResult',
  //     component: () => import('@/views/submitResult')
  //   }]
  // },
  {
    path: '/package',
    component: Layout,
    children: [{
      path: '/package',
      name: 'package',
      component: () => import('@/views/package')
    }]
  },
  {
    path: '/packageList',
    component: Layout,
    children: [{
      path: '/packageList',
      name: 'packageList',
      component: () => import('@/views/packageList')
    }]
  },
  {
    path: '/aftersale',
    component: Layout,
    children: [{
      path: '/aftersale',
      name: 'aftersale',
      component: () => import('@/views/aftersale')
    }]
  },
  {
    path: '/protect',
    component: Layout,
    children: [{
      path: '/protect',
      name: 'protect',
      component: () => import('@/views/protect')
    }]
  },
  {
    path: '/test',
    component: Layout,
    children: [{
      path: '/test',
      name: 'test',
      component: () => import('@/views/test')
    }]
  },
  // {
  //   path: '/printingMaterials',
  //   component: Layout,
  //   children: [{
  //     path: '/printingMaterials',
  //     name: 'printingMaterials',
  //     component: () => import('@/views/printingMaterials')
  //   }]
  // },
  // {
  //   path: '/development',
  //   component: Layout,
  //   children: [{
  //     path: '/development',
  //     name: 'development',
  //     component: () => import('@/views/development')
  //   }]
  // },
  {
    path: "/:catchAll(.*)", // 不识别的path自动匹配404
    name: 'NotFound',
    component: () => import('@/views/404')
  }
];

export default routes
